import React from 'react';
import Profile from './Profile';

const EventAdmin = () => {
  return (
    <div>
      <h1 className="text-3xl font-bold text-primary mb-4"> Event Admin Dashboard</h1>
      <Profile />

    </div>
  );
};

export default EventAdmin;