import React from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import CheckoutForm from '../components/CheckoutForm';  // Import the CheckoutForm you created earlier

// Initialize Stripe
const stripePromise = loadStripe('pk_test_51LrlqkLcU5LcUWNlymKkGuUfHvE3IS56R72RBTsyk2cEbC7uatYEJ437YA1oQQHM5qwK7rD6TrekYDWa5lo6dgBL009gMJjoed');  // Replace with your Stripe publishable key

const SignupProCheckout = () => {
    return (
        
        <div className="max-w-md mx-auto mt-10 p-6 bg-white shadow-md rounded-lg">
            <h1 className="text-2xl font-bold text-center mb-4">Pro Plan</h1>
            {/* Features Section */}
      <div className="plan-features mb-6">
        <h2>What's Included</h2>
        <ul>
          <li>✔️ Unlimited event/business listings</li>
          <li>✔️ Priority search placement</li>
          <li>✔️ Offer more rewards/coins</li>
          <li>✔️ Advanced analytics</li>
          <li>✔️ Ad credits for promotions</li>

        </ul>
      </div>
          
          <Elements stripe={stripePromise}>
            <CheckoutForm plan="pro" amount={999} />  {/* Pass amount in cents (e.g., $10 => 1000 cents) */}
          </Elements>
        </div>
      );
    };

export default SignupProCheckout;