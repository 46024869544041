import React from 'react';
import coins from '../assets/coins.png';
import logo from '../assets/NeigbhorCoinLogo.png';
import GoogleSignIn from '../components/GoogleSignInButton';
import WelcomeMessage from '../components/WelcomeMessage';
import Login from './Login';
import { Link } from 'react-router-dom';
import { auth } from '../firebase';
import useAuthState from '../hooks/useAuthState';

function Home() {
  const isAuthenticated = useAuthState(auth);
  const user = auth.currentUser;
  const userEmail = user ? user.email : ''; // Fetch the user email from the auth object
  const userRole = user ? user.role : 'attendee'; // Fetch role from user data, e.g., Firestore

  // Map of role display names
  const roleDisplayNames = {
    businessOwner: "Business Venue Owner",
    eventPlanner: "Event Planner",
    attendee: "Attendee"
  };

  // Function to return the dashboard link based on user role
  const getDashboardLink = () => {
    if (userRole === 'businessOwner') return '/business-dashboard';
    if (userRole === 'eventPlanner') return '/event-admin';
    return '/dashboard';
  };

  return (
    <div className="min-h-screen bg-neutralLight text-neutral flex flex-col items-center justify-center">
      <div>
        <img src={logo} alt="NeighborCoin Logo" className="" />
      </div>
      <header className="text-neutral p-4 text-center">
        <h1 className="text-3xl font-bold">Earn Coins. <br />Build Community. <br /> Spend Local.</h1>
      </header>
      <main className="p-3">
        <div>
          {isAuthenticated ? (
            // Pass the required props to WelcomeMessage
            <WelcomeMessage
              auth={auth}
              userEmail={userEmail}
              userRole={userRole}
              roleDisplayNames={roleDisplayNames}
              getDashboardLink={getDashboardLink}
            />
          ) : (
            <Login />
          )}
        </div>
        <div className="mt-4">
          <p>We’d love to hear from you! {''}
            <Link to="https://forms.gle/yJbTcohiQXQRpsRU7" target='_blank' className="text-accent font-bold hover:text-highlight">
              Give Feedback
            </Link>
          </p>
        </div>
      </main>
    </div>
  );
}

export default Home;