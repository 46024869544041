import React, { useState } from 'react';
import { Link } from 'react-router-dom';  // For navigation to relevant pages
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboardCheck, faStoreAlt, faCheckCircle, faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import CoinImage from '../assets/coin_blank.png';

const HowItWorks = () => {
  const [isAttendeesOpen, setIsAttendeesOpen] = useState(false);
  const [isEventPlannersOpen, setIsEventPlannersOpen] = useState(false);
  const [isBusinessOwnersOpen, setIsBusinessOwnersOpen] = useState(false);

  return (
    <div className="max-w-6xl mx-auto p-6 bg-white rounded-lg shadow-lg">
      <h2 className="text-3xl font-bold text-center text-primary mb-6">How NeighborhoodCoins Works</h2>
      
      {/* Introduction Section */}
      <div className="mb-8 bg-turquoise p-4 rounded-lg">
        <p className="text-neutral mb-4">
          NeighborhoodCoins is a fun way to engage with your local events and businesses! Earn coins by attending events, and redeem them for rewards or discounts from local businesses. Whether you’re hosting an event or running a business, our platform helps you connect with your community.
        </p>
      </div>
      
      {/* Attendees Section */}
      <div className="mb-8 bg-orange p-4 rounded-lg">
        <h3 className="text-2xl font-semibold text-accent mb-4 flex justify-between items-center cursor-pointer" onClick={() => setIsAttendeesOpen(!isAttendeesOpen)}>
          For Attendees
          <FontAwesomeIcon icon={isAttendeesOpen ? faChevronUp : faChevronDown} />
        </h3>
        {isAttendeesOpen && (
          <>
            <p className="text-neutral mb-4">
              Discover local events, get involved, and earn NeighborhoodCoins just for participating. Use your coins for deals and discounts at your favorite spots.
            </p>
          
            <ul className="list-disc list-inside text-neutral mb-4 space-y-2">
              <li className="flex items-center">
                <FontAwesomeIcon icon={faCheckCircle} className="text-primary mr-2" />
                <span><Link to="/events" className="text-teal text-bold hover:text-highlight hover:underline">Explore events </Link>in your neighborhood.</span>
              </li>
              <li className="flex items-center">
                <FontAwesomeIcon icon={faCheckCircle} className="text-primary mr-2" />
                <span>Earn NeighborhoodCoins by attending.</span>
              </li>
              <li className="flex items-center">
                <FontAwesomeIcon icon={faCheckCircle} className="text-primary mr-2" />
                <span>Redeem coins for discounts and rewards.</span>
              </li>
            </ul>
            <Link to="/signup" className="bg-primary text-white py-2 px-4 rounded-lg hover:bg-highlight flex items-center justify-center">
              <FontAwesomeIcon icon={faClipboardCheck} className="mr-2" />
              SignUp and Start Earning Coins
            </Link>
          </>
        )}
      </div>

      {/* Event Planners Section */}
      <div className="mb-8 bg-lightPurple p-4 rounded-lg">
        <h3 className="text-2xl font-semibold text-accent mb-4 flex justify-between items-center cursor-pointer" onClick={() => setIsEventPlannersOpen(!isEventPlannersOpen)}>
          For Event Planners
          <FontAwesomeIcon icon={isEventPlannersOpen ? faChevronUp : faChevronDown} />
        </h3>
        {isEventPlannersOpen && (
          <>
            <p className="text-neutral mb-4">
              During our BETA phase, listing events is <strong>free</strong>! Easily create events, engage the community, and offer rewards to attract attendees.
            </p>
            <ul className="list-disc list-inside text-neutral mb-4 space-y-2">
              <li className="flex items-center">
                <FontAwesomeIcon icon={faCheckCircle} className="text-primary mr-2" />
                <span>Create and manage your events through a simple Event Planner dashboard.</span>
              </li>
              <li className="flex items-center">
                <FontAwesomeIcon icon={faCheckCircle} className="text-primary mr-2" />
                <span>Offer special deals or rewards to attract attendees.</span>
              </li>
              <li className="flex items-center">
                <FontAwesomeIcon icon={faCheckCircle} className="text-primary mr-2" />
                <span>Earn NeighborhoodCoins through community participation.</span>
              </li>
            </ul>
            <Link to="/signup" className="bg-primary text-white py-2 px-4 rounded-lg hover:bg-highlight flex items-center justify-center">
              <FontAwesomeIcon icon={faClipboardCheck} className="mr-2" />
              Start Listing Your Event
            </Link>
          </>
        )}
      </div>

      {/* Business Owners Section */}
      <div className="mb-8 bg-green p-4 rounded-lg">
        <h3 className="text-2xl font-semibold text-accent mb-4 flex justify-between items-center cursor-pointer" onClick={() => setIsBusinessOwnersOpen(!isBusinessOwnersOpen)}>
          For Business Venue Owners
          <FontAwesomeIcon icon={isBusinessOwnersOpen ? faChevronUp : faChevronDown} />
        </h3>
        {isBusinessOwnersOpen && (
          <>
            <p className="text-neutral mb-4">
              Want to reach local event-goers? Get your business featured in the NeighborhoodCoins app, offer rewards, and drive traffic during our special MVP phase.
            </p>
            <ul className="list-disc list-inside text-neutral mb-4 space-y-2">
              <li className="flex items-center">
                <FontAwesomeIcon icon={faCheckCircle} className="text-primary mr-2" />
                <span>Offer exclusive rewards to event-goers.</span>
              </li>
              <li className="flex items-center">
                <FontAwesomeIcon icon={faCheckCircle} className="text-primary mr-2" />
                <span>Feature your business to attract locals.</span>
              </li>
              <li className="flex items-center">
                <FontAwesomeIcon icon={faCheckCircle} className="text-primary mr-2" />
                <span>Build loyalty with NeighborhoodCoins.</span>
              </li>
            </ul>
            <Link to="/signup" className="bg-primary text-white py-2 px-4 rounded-lg hover:bg-highlight flex items-center justify-center">
              <FontAwesomeIcon icon={faStoreAlt} className="mr-2" />
              Get Your Business Featured
            </Link>
          </>
        )}
      </div>
    </div>
  );
};

export default HowItWorks;