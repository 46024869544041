import { Link } from 'react-router-dom';
import LittleHaitiBanner from '../assets/LittleHaiti_flags.png';
import WynwoodBanner from '../assets/wynwood.png';
import DesignDistrictBanner from '../assets/designdistrict.png';
import OvertownBanner from '../assets/overtown.png';
import Miami from '../assets/miami.png';

export const neighborhoodData = {
  miami: {
  littlehaiti: {
      name: 'Little Haiti',
      description: 'Little Haiti is a vibrant neighborhood in Miami, Florida. It is known for its rich Haitian culture and history. The neighborhood is home to many Haitians and has a strong sense of community. Little Haiti is a great place to explore if you are interested in learning more about Haitian culture and traditions.',
      headerImage: LittleHaitiBanner,
      center: { lat: 25.8376, lng: -80.1983 },
      boundaryCoordinates: [[25.832, -80.205], [25.832, -80.191], [25.818, -80.191], [25.818, -80.205]],
  },
  wynwood: {
  name: 'Wynwood',
  description: 'Wynwood is a trendy neighborhood in Miami, Florida. It is known for its vibrant street art, galleries, and hipster vibe. The neighborhood is home to many artists, creatives, and entrepreneurs. Wynwood is a great place to explore if you are interested in art, culture, and design.',
  headerImage: WynwoodBanner,
  center: { lat: 25.8011, lng: -80.1995 },
  boundaryCoordinates: [[25.804, -80.207], [25.804, -80.193], [25.79, -80.193], [25.79, -80.207]],
  },
  designdistrict: {
  name: 'Design District',
  description: 'The Design District is a chic neighborhood in Miami, Florida. It is known for its luxury fashion stores, art galleries, and design showrooms. The neighborhood is home to many high-end brands, designers, and artists. The Design District is a great place to explore if you are interested in fashion, design, and architecture.',
  headerImage: DesignDistrictBanner,
  center: {lat: 25.806, lng: -80.185},
  boundaryCoordinates: [
  [25.813, -80.192], 
  [25.813, -80.178], 
  [25.799, -80.178], 
  [25.799, -80.192]],
  },
  overtown: {
    name: 'Overtown',
    description: 'Overtown is a historic neighborhood in Miami, Florida. It is known for its rich African American culture and history. The neighborhood is home to many historic sites, jazz clubs, and soul food restaurants. Overtown is a great place to explore if you are interested in African American history and culture.',
    headerImage: OvertownBanner,
    center: {lat: 25.787, lng: -80.199},
    boundaryCoordinates: [[25.796, -80.207], [25.796, -80.193], [25.782, -80.193], [25.782, -80.207]],

  },
  downtown: {
    name: 'Downtown',
    description: 'Downtown is the central business district of Miami, Florida. It is known for its skyscrapers, museums, and cultural institutions. The neighborhood is home to many businesses, government offices, and tourist attractions. Downtown is a great place to explore if you are interested in history, architecture, and urban life.',
    headerImage: Miami,
    center: { lat: 25.774, lng: -80.193 },
    boundaryCoordinates: [[25.774, -80.2], [25.774, -80.186], [25.76, -80.186], [25.76, -80.2]],
  },
  miamibeach: {
    name: 'Miami Beach',
    description: 'Miami Beach is a popular resort city in Miami, Florida. It is known for its beautiful beaches, art deco architecture, and vibrant nightlife. The neighborhood is home to many hotels, restaurants, and entertainment venues. Miami Beach is a great place to explore if you are interested in sun, sand, and sea.',
    headerImage: 'https://via.placeholder.com/800x200',
    center: { lat: 25.792, lng: -80.13 },
    boundaryCoordinates: [[25.792, -80.137], [25.792, -80.123], [25.778, -80.123], [25.778, -80.137]],
  },
}
,};
  

function Neighborhoods() {
  return (
    <div>
      <h1>Explore Neighborhoods</h1>
      <ul>
          {Object.keys(neighborhoodData).map((neighborhood) => {
            const { name } = neighborhoodData[neighborhood]; // Deconstructing for readability
            return (
              <li key={neighborhood}>
                <Link to={`/${encodeURIComponent(neighborhood)}`}>
                  {name}
                </Link>
              </li>
            );
          })}
    </ul>
    </div>
  );
}

export default Neighborhoods;
