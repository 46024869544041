import React, { useState } from 'react';
import { getAuth, sendSignInLinkToEmail } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import GoogleButton from '../components/GoogleButton';

const Login = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
   const [isAuthenticated, setIsAuthenticated] = useState(false);
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    const auth = getAuth();
    const actionCodeSettings = {
      // The URL the user will be redirected to after sign-in.
      url: `${window.location.origin}/finishSignUp`, 
      handleCodeInApp: true, // Required to receive the email link in the app
    };

   
    try {
      await sendSignInLinkToEmail(auth, email, actionCodeSettings);
      window.localStorage.setItem('emailForSignIn', email); // Save email to localStorage to complete sign-in later
      setMessage('Check your email for the login link!');
      setEmail('');
    } catch (err) {
      setError(`Failed to send login link: ${err.message}`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="max-w-md mx-auto p-6 bg-white rounded-lg shadow-lg">
      <h2 className="text-2xl font-bold mb-6 text-center">Login</h2>
      {message && <p className="text-teal-700">{message}</p>}
      {error && <p className="text-red-500">{error}</p>}
      {/* <form onSubmit={handleLogin}>
        <div className="mb-4">
          <input
            type="email"
            name="email"
            value={email}
            placeholder='Enter your email'
            onChange={(e) => setEmail(e.target.value)}
            required
            className="w-full px-4 py-2 border border-gray-300 rounded-lg"
          />
        </div>
        <button
          type="submit"
          className="w-full bg-secondary text-white py-2 px-4 rounded-lg hover:bg-teal"
          disabled={loading}
        >
          {loading ? 'Sending Login Link ...' : 'Send Login Link'}
        </button>
      </form> */}
      <p className="mt-4 text-center">
       
        <GoogleButton />
      </p>
    </div>
  );
};

export default Login;