import React, { useEffect, useState } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../firebase';
import { neighborhoodData } from './Neighborhoods';
import NeighborhoodMap from '../components/NeighborhoodMap';

const NeighborhoodPage = () => {
  const { cityName, neighborhoodName } = useParams();
  const decodedCityName = decodeURIComponent(cityName);
  const decodedNeighborhoodName = decodeURIComponent(neighborhoodName);
  const [events, setEvents] = useState([]);
  const [businesses, setBusinesses] = useState([]);
  const [loadingEvents, setLoadingEvents] = useState(true);
  const [loadingBusinesses, setLoadingBusinesses] = useState(true);

  const normalizeName = (name) => {
    return name.toLowerCase().replace(/\s+/g, '');
  };

  const navigate = useNavigate();
  const normalizedCityName = normalizeName(decodedCityName);
  const normalizedNeighborhoodName = normalizeName(decodedNeighborhoodName);
  const [neighborhoodInformation, setNeighborhoodInformation] = useState(null);

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const q = query(collection(db, 'events'), where('neighborhood', '==', decodedNeighborhoodName));
        const querySnapshot = await getDocs(q);
        const eventsList = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setEvents(eventsList);
      } catch (error) {
        console.error('Error fetching events: ', error);
      } finally {
        setLoadingEvents(false);
      }
    };

    fetchEvents();
  }, [decodedNeighborhoodName]);

  useEffect(() => {
    const fetchBusinesses = async () => {
      try {
        const q = query(collection(db, 'businesses'), where('neighborhood', '==', decodedNeighborhoodName));
        const querySnapshot = await getDocs(q);
        const businessesList = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setBusinesses(businessesList);
      } catch (error) {
        console.error('Error fetching businesses: ', error);
      } finally {
        setLoadingBusinesses(false);
      }
    };

    fetchBusinesses();
  }, [decodedNeighborhoodName]);

  // Helper function to check if the event has ended
  const isEventEnded = (eventDate) => {
    const now = new Date();
    const eventDateObj = eventDate.toDate ? eventDate.toDate() : new Date(eventDate); // Convert Firestore Timestamp to Date if necessary
    return eventDateObj < now; // Check if event date is in the past
  };

  useEffect(() => {
    // Validate and find the neighborhood info from neighborhoodData
    if (neighborhoodData && typeof neighborhoodData === 'object') {
      const cityData = neighborhoodData[normalizedCityName];
      if (cityData && typeof cityData === 'object') {
        const neighborhood = cityData[normalizedNeighborhoodName];
        if (neighborhood) {
          setNeighborhoodInformation(neighborhood);
        } else {
          console.error(`Neighborhood "${decodedNeighborhoodName}" not found in city "${decodedCityName}"`);
          navigate('/error'); // Redirect to an error page or handle the error appropriately
        }
      } else {
        console.error(`City "${decodedCityName}" not found in neighborhoodData`);
        navigate('/error'); // Redirect to an error page or handle the error appropriately
      }
    } else {
      console.error('Invalid neighborhoodData structure');
      navigate('/error'); // Redirect to an error page or handle the error appropriately
    }
  }, [decodedCityName, decodedNeighborhoodName, navigate, normalizedCityName, normalizedNeighborhoodName]);

  if (!neighborhoodInformation) {
    return <div>Loading...</div>;
  }

  return (
    <div className="max-w-4xl mx-auto p-4">
      <div>
        <div className="relative mb-5">
          <img
            src={neighborhoodInformation?.headerImage}
            alt={neighborhoodInformation?.name}
            className="w-full h-48 object-cover rounded-lg"
          />
        
          <div className="absolute inset-0 flex items-center justify-center">
            <h1 className="text-4xl font-bold text-white bg-black bg-opacity-30 px-8 py-6 rounded-lg">
              {neighborhoodInformation?.name} Events
            </h1>
           
          </div>
          
        </div>
        <div>
             <p className="text-1xl"> {neighborhoodInformation?.description} </p>
          </div>
      </div>

      {/* Display Events */}
      {loadingEvents ? (
        <p>Loading events...</p>
      ) : (
        <>
          {events.length > 0 ? (
            events.map((event) => {
              const eventDate = new Date(event.date.seconds * 1000); // Convert Firestore Timestamp to Date object
              const eventEnded = isEventEnded(eventDate);
              
              return (
                <div key={event.id} className="flex items-center justify-between p-4 border-b border-gray-300 rounded-lg shadow-lg bg-white">
                  <div className="flex-1 mr-4">
                    <h3 className="text-lg font-semibold">{event.name}</h3>
                    <p className="text-gray-500 text-bold mb-3">
                      {eventDate.toLocaleDateString('en-US', {
                        year: 'numeric',
                        month: 'long',
                        day: '2-digit',
                      })}
                    </p>
                    <p className="text-gray-600">{event.location}</p>
                    
                    {eventEnded && (
                      <span className="text-red-600 font-bold">Event Ended</span>
                    )}
                  </div>
                  <div className="w-24 h-24">
                  <Link to={`/events/${event.id}`}>
                    <img src={event.imageUrl || "path_to_default_image.jpg"} alt="Thumbnail" className="w-full h-full object-cover" />
                    </Link>
                  </div>
                </div>
              );
            })
          ) : (
            <p className="py-6 px-6 mb-5 text-bold-red">No events found.</p>
          )}
        </>
      )}

      <h1 className="text-3xl font-bold mt-8">Businesses</h1>

      {/* Display Businesses */}
      {loadingBusinesses ? (
        <p>Loading businesses...</p>
      ) : (
        <>
          {businesses.length > 0 ? (
            <ul>
              {businesses.map((business) => (
                <li key={business.id}>{business.businessName}</li>
              ))}
            </ul>
          ) : (
            <p>No businesses found.</p>
          )}
        </>
      )}
    </div>
  );
};

export default NeighborhoodPage;