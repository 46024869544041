import React from 'react';
import Navbar from './Navbar';
import { FaFacebook, FaYoutube, FaInstagram } from 'react-icons/fa'; // Import icons


function Layout({ children }) {
  return (
    <div className="max-w-4xl mx-auto min-h-screen bg-neutralLight text-neutral flex flex-col">
      <Navbar />
     
      <main className="flex-grow p-2 bg-neutralLight">
        {children}
      </main>
      <footer className="bg-teal text-mustard p-4 text-xs text-center">
<p>NeighborhoodCoins powered by <a className="text-white-500 underline ml-1" href="https://www.unieros.com"> Unieros LLC </a> </p>
          <p>&copy; 2024 NeighborhoodCoins. All rights reserved.</p>
    
        {/* Social Media Icons */}
        <div className="flex justify-center space-x-4 mt-4">
          <a href="https://www.facebook.com/profile.php?id=61565535629392" target="_blank" rel="noopener noreferrer" aria-label="Facebook">
            <FaFacebook className="text-white-500 hover:text-neutral duration-200" size={24} />
          </a>
          <a href="https://www.youtube.com/@neighborhoodcoins" target="_blank" rel="noopener noreferrer" aria-label="Twitter">
            <FaYoutube className="text-white-500 hover:text-neutral duration-200" size={24} />
          </a>
          <a href="https://www.instagram.com/neighborhoodcoins" target="_blank" rel="noopener noreferrer" aria-label="Instagram">
            <FaInstagram className="text-white-500 hover:text-neutral duration-200" size={24} />
          </a>
        </div>
        </footer>
    </div>
  );
}

export default Layout;