import React, { useEffect, useState } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { db, auth } from '../firebase';
import { doc, getDoc, setDoc, updateDoc, arrayUnion, increment } from 'firebase/firestore';
import Map from '../components/Map';
import { FacebookShareButton, TwitterShareButton, LinkedinShareButton, WhatsappShareButton, EmailShareButton, } from 'react-share';
import { FacebookIcon, TwitterIcon, LinkedinIcon, WhatsappIcon, EmailIcon } from 'react-share';
import CoinImage from '../assets/coin_blank.png';
import { Helmet } from 'react-helmet-async';
import GoogleSignInButton from '../components/GoogleSignInButton';

const EventDetails = () => {
  const shareUrl = window.location.href;  // Current page URL for sharing
  const { eventId } = useParams();  // Get the event ID from the URL
  const navigate = useNavigate();
  const [event, setEvent] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [rsvpStatus, setRsvpStatus] = useState(false);
  const [checkedIn, setCheckedIn] = useState(false); // Check-in state
  const [showSignup, setShowSignup] = useState(false); // Show or hide sign-up form

  useEffect(() => {
    const fetchEvent = async () => {
      try {
        const eventRef = doc(db, 'events', eventId);
        const eventSnapshot = await getDoc(eventRef);

        if (eventSnapshot.exists()) {
          const eventData = eventSnapshot.data();
          setEvent(eventData);

          // Check if the current user has RSVP'd to this event
          const user = auth.currentUser;
          if (user && eventData.rsvps && eventData.rsvps.includes(user.email)) {
            setRsvpStatus(true);
          }
        } else {
          setError('Event not found');
        }
      } catch (err) {
        setError('Failed to fetch event details: ' + err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchEvent();
  }, [eventId]);

  const handleRsvp = async () => {
    const user = auth.currentUser;

    if (!user) {
      setShowSignup(true);  // Show signup form if user is not authenticated
      return;
    }

    try {
      const eventRef = doc(db, 'events', eventId);

      // Update the event document by adding the user's email to the RSVPs array
      await updateDoc(eventRef, {
        rsvps: arrayUnion(user.email)
      });

      setRsvpStatus(true);
    } catch (err) {
      setError('Failed to RSVP: ' + err.message);
    }
  };

  const handleCheckIn = async (qrData) => {
    const user = auth.currentUser;
    const checkInRef = doc(db, 'check-ins', `${eventId}_${user.uid}`); // Unique ID for this check-in
    await setDoc(checkInRef, {
  eventId: eventId,
  userId: user.uid,
  checkInTime: new Date(),
});

    if (!user) {
     setShowSignup(true);  // Show signup form if user is not authenticated
      return;
    }

    try {
      const eventRef = doc(db, 'events', eventId);
      const userRef = doc(db, 'users', user.uid);

      // Mark user as checked in by adding the user's email to the checkedIn array
      await updateDoc(eventRef, {
        checkIns: arrayUnion(user.email)
      });

      // Add NeighborhoodCoins to the user's balance
      await updateDoc(userRef, {
        neighborCoinBalance: increment(event.coins),
      });

      setCheckedIn(true);
    } catch (err) {
      setError('Failed to check in: ' + err.message);
    }
  };

  const isEventActive = (eventDate, eventStartTime, eventEndTime) => {
    const currentTime = new Date();
  
    // Check if eventStartTime and eventEndTime are strings before trying to split
    if (typeof eventStartTime === 'string' && typeof eventEndTime === 'string') {
      const eventStartDateTime = new Date(eventDate.toDate());
      eventStartDateTime.setHours(...eventStartTime.split(':').map(Number));
  
      const eventEndDateTime = new Date(eventDate.toDate());
      eventEndDateTime.setHours(...eventEndTime.split(':').map(Number));
  
      return currentTime >= eventStartDateTime && currentTime <= eventEndDateTime;
    } else {
      console.error('Invalid time format for event start or end time');
      return false;
    }
  };
  const closeModal = () => {
    setShowSignup(false);
  };

  if (loading) return <p>Loading event details...</p>;
  if (error) return <p>{error}</p>;

  const currentDate = new Date().toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: '2-digit',
    timeZone: 'America/New_York'
  });

  const eventDateString = event && new Date(event.date).toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: '2-digit',
    timeZone: 'America/New_York'
  });

  return (
    <div className="max-w-4xl mx-auto p-6 bg-white rounded-lg shadow-lg">
      {event && (
        <>
          {/* Helmet for dynamic SEO and social media sharing */}
          <Helmet>
            <title>{event.name} - Neighborhood Coins</title>
            <meta name="description" content={event.description} />
            <meta property="og:image" content={event.imageUrl} />
            <meta property="og:url" content={shareUrl} />
          </Helmet>
      
          <div className="relative">
            <img src={event.imageUrl} alt={event.name} className="w-full h-60 object-fit rounded-lg mb-4" />
            <div className="absolute bottom-2 right-2 text-white py-1 px-3 rounded-full flex items-center justify-center w-20 h-20" style={{
              backgroundImage: `url(${CoinImage})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
            }}>
              <p className="text-center">
                <strong className="block text-shadow">+ {event.coins}</strong>
                <span className="block text-sm text-shadow">Coins</span>
              </p>
            </div>
          </div>
          <h1 className="text-3xl font-bold mb-4">{event.name}</h1>
          
          <p className="text-neutral mb-2">
            <strong>Date:</strong> {(() => {
              const date = event.date instanceof Object && event.date.toDate
                ? event.date.toDate()
                : new Date(event.date);
              return date.toLocaleDateString('en-US', {
                year: 'numeric',
                month: 'long',
                day: '2-digit',
                timeZone: 'America/New_York'
              });
            })()}
          </p>
          <p className="text-neutral mb-4">{event.description}</p>
          <p className="text-neutral mb-4">
            <strong>Time:</strong> {new Date(`1970-01-01T${event.doorsOpenTime}`).toLocaleTimeString('en-US', {
              hour: '2-digit',
              minute: '2-digit',
              hour12: true,
            })} - {new Date(`1970-01-01T${event.endTime}`).toLocaleTimeString('en-US', {
              hour: '2-digit',
              minute: '2-digit',
              hour12: true,
            })}
          </p>          <p className="text-neutral mb-4"><strong>Organizer:</strong> {event.organizer}</p>
          <div className="bg-tealLight bg-opacity-75 p-1 rounded-tl-lg">
            <p className="text-teal mb-2 text-small">
              <span className="font-bold">Neighborhood:</span>{' '}
              <Link to={`/miami/${event.neighborhood}`}><span className="font-medium ml-2">{event.neighborhood}</span></Link>
            </p>
          </div>
          <div className="mt-6">
            <Map event={event} />
          </div>
            
          <div className="mt-6">
            {/* RSVP Button and Check-In Section */}
            <div className="flex space-x-4 mt-6">
              {rsvpStatus ? (
                <p className="text-green text-bold m-3 ">You have RSVP'd to this event!</p>
              ) : (
                <button
                  className="mt-4 bg-primary text-white py-2 px-4 font-bold rounded hover:bg-highlight transition-colors duration-300"
                  onClick={handleRsvp}
                >
                  RSVP/Join
                </button>
              )}
            </div>
            {/* Modal for Login/Signup */}
          {showSignup && (
            <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50" onClick={closeModal}>
              <div className="bg-white p-6 rounded-lg shadow-lg max-w-md text-center">
                <h2 className="text-xl font-bold mb-4">Please Sign Up to RSVP</h2>
                <GoogleSignInButton />
                <button
                  className="mt-4 bg-gray-500 text-white py-2 px-4 font-bold rounded hover:bg-gray-700 transition-colors duration-300"
                  onClick={closeModal}
                >
                  Close
                </button>
              </div>
            </div>
          )}

              {/* Check-In Section */}
              {isEventActive(event.date, event.startTime, event.endTime) && (

                <div>
                {checkedIn ? (
                  <p className="text-greenDark px-4 py-4 bg-mustard text-bold">
                    You've successfully checked in and earned your reward! 🎉 Enjoy your {event.coins} NeighborhoodCoins.
                  </p>
                ) : (
                  <button
                    className="mt-4 bg-primary text-white py-2 px-4 font-bold rounded hover:bg-highlight transition-colors duration-300"
                    onClick={handleCheckIn}
                  >
                    Check-In
                  </button>
                )}
                </div>
              )}
            
          </div>

          {/* Social Share Buttons */}
          <div className="flex space-x-4 mt-6">
            <EmailShareButton url={shareUrl} subject={event.title} body={event.description}>
              <EmailIcon size={32} round />
            </EmailShareButton>
            <FacebookShareButton url={shareUrl} quote={event.title}>
              <FacebookIcon size={32} round />
            </FacebookShareButton>
            <TwitterShareButton url={shareUrl} title={event.title}>
              <TwitterIcon size={32} round />
            </TwitterShareButton>
            <LinkedinShareButton url={shareUrl} title={event.title}>
              <LinkedinIcon size={32} round />
            </LinkedinShareButton>
            <WhatsappShareButton url={shareUrl} title={event.title}>
              <WhatsappIcon size={32} round />
            </WhatsappShareButton>
          </div>

          {/* Back to Events */}
          <button
            onClick={() => navigate('/events')}  // Navigate back to Events page
            className="bg-secondary text-white py-2 px-4 m-3 rounded hover:bg-highlight transition-colors duration-300"
          >
            Back to Events
          </button>

          {/* Edit Button */}
          {auth.currentUser && auth.currentUser.uid === event.organizerId && (
            <button
              onClick={() => navigate(`/edit-event/${eventId}`)}  // Navigate to the EditEventForm page
              className="bg-secondary text-white py-2 px-4 rounded hover:bg-highlight transition-colors duration-300 mt-4"
            >
              Edit Event
            </button>
          )}

          
        </>
      )}
    </div>
  );
};

export default EventDetails;