import React, { useEffect, useState } from 'react';
import { auth, db, storage } from '../firebase'; // Import Firebase instance
import { doc, getDoc, collection, query, where, getDocs, updateDoc, setDoc } from 'firebase/firestore';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage'; // Firebase Storage methods
import { Link, useNavigate } from 'react-router-dom';
import MyOffers from '../components/MyOffers';

const BusinessDashboard = () => {
  const [businessData, setBusinessData] = useState(null);
  const [events, setEvents] = useState([]);
  const [logoFile, setLogoFile] = useState(null); // State for storing the selected logo file
  const [uploading, setUploading] = useState(false); // State for tracking upload status
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchBusinessData = async () => {
      const user = auth.currentUser;
      if (user) {
        try {
          // Get business owner details
          const businessDoc = await getDoc(doc(db, 'businesses', user.uid));
          if (businessDoc.exists()) {
            setBusinessData(businessDoc.data());
          } else {
            // If the business data doesn't exist, create a new entry for the business
            const newBusinessData = {
              name: '', // default name, can be updated by the user
              ownerEmail: user.email,
              createdAt: new Date(),
              logoUrl: '',
              // Add other fields as necessary
            };
            await setDoc(doc(db, 'businesses', user.uid), newBusinessData);
            setBusinessData(newBusinessData); // Set the default data to state
          }

          // Fetch events associated with this business
          const q = query(collection(db, 'events'), where('businessId', '==', user.uid));
          const querySnapshot = await getDocs(q);
          const eventsList = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
          setEvents(eventsList);

        } catch (err) {
          setError(err.message);
        }
      } else {
        setError('User not authenticated');
      }
    };

    fetchBusinessData();
  }, []);

  const handleCreateOffer = () => {
    // Logic to ensure they have business profile set up
    if (!businessData) {
      setError('Please set up your business profile first.');
      return;
    }
    // Logic to create new offers for event attendees and pass busineeData to the new offer creation page
    navigate('/create-offer', {state: {businessData }});  // Example of navigating to a new offer creation page
  };

  const handleLogoChange = (e) => {
    setLogoFile(e.target.files[0]); // Store the selected logo file
  };

  const handleLogoUpload = async () => {
    if (!logoFile) {
      setError('Please select a logo file to upload.');
      return;
    }

    const user = auth.currentUser;
    if (!user) {
      setError('User not authenticated');
      return;
    }

    const logoRef = ref(storage, `business_logos/${user.uid}/${logoFile.name}`); // Reference to Firebase Storage

    setUploading(true); // Start uploading

    const uploadTask = uploadBytesResumable(logoRef, logoFile);

    uploadTask.on(
      'state_changed',
      (snapshot) => {
        // Optional: Handle progress here if needed
      },
      (error) => {
        console.error('Logo upload failed: ', error);
        setError('Logo upload failed. Please try again.');
        setUploading(false);
      },
      async () => {
        // Upload completed successfully, get the download URL
        const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
        
        // Update the business profile with the logo URL in Firestore
        const businessRef = doc(db, 'businesses', user.uid);
        await updateDoc(businessRef, {
          logoUrl: downloadURL,
        });

        setMessage('Logo uploaded successfully!');
        setUploading(false);
        setLogoFile(null); // Clear the selected file
        setBusinessData((prevData) => ({ ...prevData, logoUrl: downloadURL })); // Update businessData with the new logo URL
      }
    );
  };

  return (
    <div className="max-w-4xl mx-auto p-6 bg-white rounded-lg shadow-lg">
      <h2 className="text-3xl font-bold text-primary mb-4">Business Dashboard</h2>

      {error && <p className="text-red-500">{error}</p>}
      {message && <p className="text-green-500">{message}</p>}
      
      {businessData && (
        <>
          <div className="mb-6">
            <h3 className="text-xl font-semibold">Business Profile</h3>
            {businessData.logoUrl ? (
              <div className="mb-4">
                <img src={businessData.logoUrl} alt="Business Logo" className="w-24 h-24 object-cover rounded-full" />
              </div>
            ) : (
              <div className="mb-4">
                <label className="block text-neutral mb-2">Upload Business Logo</label>
                <input type="file" onChange={handleLogoChange} className="w-full px-4 py-2 border border-gray-300 rounded-lg" />
                <button
                  onClick={handleLogoUpload}
                  className="bg-primary text-white py-2 px-4 rounded-lg hover:bg-highlight mt-4"
                  disabled={uploading}
                >
                  {uploading ? 'Uploading...' : 'Upload Logo'}
                </button>
              </div>
            )}
          
            <button 
              onClick={() => navigate('/edit-business-profile')}
              className="bg-primary text-white py-2 px-4 rounded-lg hover:bg-highlight mt-4"
            >
              Edit Profile
            </button>
          </div>

          <div className="mb-6">
            <h3 className="text-xl font-semibold">Your Events</h3>
            {events.length > 0 ? (
              <ul className="list-disc list-inside">
                {events.map((event) => (
                  <li key={event.id}>
                    <Link to={`/events/${event.id}`} className="text-primary hover:underline">
                      {event.name}
                    </Link> - {event.date}
                  </li>
                ))}
              </ul>
            ) : (
              <p>No events linked to your business.</p>
            )}
          </div>
           {/* MyOffers Component */}
           <MyOffers />

          <div className="mb-6">
          
            <button 
              onClick={handleCreateOffer}
              className="bg-secondary text-white py-2 px-4 rounded-lg hover:bg-highlight mt-4"
            >
              Create Offer
            </button>
          </div>
          <div className="mt-4">
          <p> We’d love to hear from you! Click here to {''}
          <Link to="https://forms.gle/yJbTcohiQXQRpsRU7" target='_blank' className="text-accent font-bold hover:text-highlight">
            Give Feedback
          </Link>
          </p>
        </div>
        </>
      )}
    </div>
  );
};

export default BusinessDashboard;