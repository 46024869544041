import React, { useState, useEffect } from 'react';
import { getAuth, sendSignInLinkToEmail } from 'firebase/auth';
import { db } from '../firebase';  // Firestore instance
import { doc, setDoc, getDoc } from 'firebase/firestore';  // Firestore methods
import { useNavigate, useLocation, Link } from 'react-router-dom';
import WelcomeMessage from './WelcomeMessage'; // Adjust the import path as necessary


const RoleSignUp = (props) => {
  const location = useLocation();
  const emailFromGoogle = location.state?.email || '';  // Get email from Google sign-in
  const [formData, setFormData] = useState({
    email: emailFromGoogle, // Prefill the email field with the Google email
    role: 'attendee'
  });

  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userEmail, setUserEmail] = useState('');
  const [userRole, setUserRole] = useState('');  // Store the logged-in user's role
  const [submitted, setSubmitted] = useState(false); // Track if the form is submitted

  const navigate = useNavigate();

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };
  const handleGoogleSignIn = async (user) => {
    const userData = {
      name: user.displayName,
      email: user.email,
  };
  props.onReceiveUserData(userData);
};

  // Check if the user is already authenticated
  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        setIsAuthenticated(true);
        setUserEmail(user.email);  // Set the logged-in user's email

        // Fetch the user's role from Firestore
        const userRef = doc(db, 'users', user.uid);
        const userDoc = await getDoc(userRef);
        if (userDoc.exists()) {
          setUserRole(userDoc.data().role);  // Set the user's role
        }
      } else {
        setIsAuthenticated(false);
      }
    });

    return () => unsubscribe();  // Cleanup subscription on unmount
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (!validateEmail(formData.email)) {
      setError('Invalid email address');
      setLoading(false);
      return;
    }

    try {
      const auth = getAuth();
      const actionCodeSettings = {
        url: `${window.location.origin}/finishSignUp`,
        handleCodeInApp: true,
      };

      await sendSignInLinkToEmail(auth, formData.email, actionCodeSettings);

      // Store email temporarily in Firestore with role selection
      await setDoc(doc(db, 'pendingUsers', formData.email), {
        email: formData.email,
        role: formData.role,
        createdAt: new Date(),
      });

      setMessage('Check your email for the sign-in link!');
      setSubmitted(true); // Mark the form as submitted
      setFormData({ email: '', role: 'attendee' });
    } catch (err) {
      console.error('Error sending sign-in link:', err); // Log the exact error
      setError('Failed to send sign-in link: ' + err.message);
    } finally {
      setLoading(false);
    }
  };

  const getDashboardLink = () => {
    switch (userRole) {
      case 'eventPlanner':
        return '/event-admin';
      case 'businessOwner':
        return '/business-dashboard';
      default:
        return '/dashboard';
    }
  };
   // Mapping of internal role values to user-friendly display names
   const roleDisplayNames = {
    attendee: 'Attendee',
    eventPlanner: 'Event Planner',
    businessOwner: 'Business Owner'
  };

  return (
    <div className="max-w-md mx-auto p-6 bg-mustard rounded-lg shadow-lg">
     {/* If user is already authenticated, no need to send sign-in link */}
     {isAuthenticated ? (
        <WelcomeMessage
          userEmail={userEmail}
          userRole={userRole}
          roleDisplayNames={roleDisplayNames}
          getDashboardLink={getDashboardLink}
        />
      ) : (
        // If not authenticated, show the sign-up form
        <>
          <div className="text-center">
          <h2 className="text-center px-3 mb-3  font-bold">Welcome to NeighborhoodCoins </h2>
          {message && (
            <p className="text-neutralLight font-bold bg-green p-2 rounded-lg">{message}</p>
          )}
          {error && <p className="text-red-500">{error}</p>}
          </div>

          {!submitted && ( // Hide the form after submission
            <>
              <div className="mb-6">
               
                
                {/* Login Link */}
                {/* <div className="w-full">
                  <Link to="/login" className="text-white text-center bg-coral hover:bg-teal w-full mb-5 font-bold py-2 px-4 rounded-lg block">
                    Login
                  </Link> */}
                
              </div>
              <p className="text-small text-center mb-4">New to NeighborhoodCoins? </p>
              <p className="font-bold">Get Started</p>
              <form onSubmit={handleSubmit}>
                <div className="mb-4">
                  <input
                    type="email"
                    name="email"
                    value={formData.email}
                    placeholder="Enter your email"
                    onChange={handleChange}
                    required
                    className="w-full px-4 py-2 border border-gray-300 rounded-lg"
                  />
                </div>
                <div className="mb-4">
                  <label className="block font-bold text-neutral mb-2">I am signing up as:</label>
                  <select
                    name="role"
                    value={formData.role}
                    onChange={handleChange}
                    className="w-full px-4 py-2 border border-gray-300 rounded-lg"
                  >
                    <option value="attendee">Attendee</option>
                    <option value="eventPlanner">Event Organizer</option>
                    <option value="businessOwner">Venue Owner</option>
                  </select>
                </div>
                <button
                  type="submit"
                  className={`w-full bg-teal text-white py-2 px-4 font-bold rounded-lg hover:bg-burntOrange ${loading ? 'opacity-50 cursor-not-allowed' : ''}`}
                  disabled={loading}
                >
                  {loading ? 'Sign Up ...' : 'Sign Up'}
                </button>
              </form>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default RoleSignUp;