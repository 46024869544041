import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { db, auth } from '../firebase';
import { collection, getDocs, doc, updateDoc, arrayUnion } from 'firebase/firestore';
import Map from '../components/Map';
import { Link, useNavigate } from 'react-router-dom';
import CoinImage from '../assets/coin_blank.png';
import { Timestamp } from "firebase/firestore";
import Banner from '../assets/neighborhood_coin_banner.png';
import './Events.css';
import GoogleSignInButton from '../components/GoogleSignInButton';
import EventList from '../components/EventList';

const Events = () => {
  const navigate = useNavigate();
  const [events, setEvents] = useState([]);
  const [error, setError] = useState(null);
  const [selectedEventId, setSelectedEventId] = useState(null);
  const [showSignup, setShowSignup] = useState(false);  // Track if signup form should be shown
  const [rsvpMessages, setRsvpMessages] = useState({});  // Object to store RSVP messages for each event
  const [currentTime, setCurrentTime] = useState(new Date());

  const handleIconClick = (eventId) => {
    setSelectedEventId(selectedEventId === eventId ? null : eventId);
  };

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const eventsCollection = collection(db, 'events');
        const eventsSnapshot = await getDocs(eventsCollection);
        const eventsList = eventsSnapshot.docs.map(doc => {
          const data = doc.data();
          return {
            id: doc.id,
            ...data,
            date: data.date instanceof Timestamp ? data.date.toDate() : new Date(data.date),
            startTime: data.startTime || '00:00',
            endTime: data.endTime || '23:59'
          };
        });
        setEvents(eventsList);
      } catch (err) {
        setError('Failed to fetch events: ' + err.message);
      }
    };

    fetchEvents();
  }, []);

  const parseTime = (timeString) => {
    if (typeof timeString === 'string') {
      const [hours, minutes] = timeString.split(':').map(Number);
      return { hours: hours || 0, minutes: minutes || 0 };
    } else if (timeString instanceof Date) {
      return { hours: timeString.getHours(), minutes: timeString.getMinutes() };
    }
    return { hours: 0, minutes: 0 }; // Default to midnight if parsing fails
  };

  // Function to categorize events
  const categorizeEvents = (events) => {
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);
    const endOfWeek = new Date(today);
    endOfWeek.setDate(today.getDate() + (6 - today.getDay()));

    const categorized = {
      today: [],
      thisWeek: [],
      upcoming: [],
    };

    events.forEach(event => {
      const eventDate = new Date(event.date);
      const { hours: startHours, minutes: startMinutes } = parseTime(event.startTime);
      const { hours: endHours, minutes: endMinutes } = parseTime(event.endTime);

      const eventStartTime = new Date(eventDate);
      eventStartTime.setHours(startHours, startMinutes, 0, 0);

      const eventEndTime = new Date(eventDate);
      eventEndTime.setHours(endHours, endMinutes, 59, 999);
      
      if (eventDate >= today && eventDate < tomorrow) {

        if (eventEndTime >= now) {
          categorized.today.push(event);
        }
        // Event is happening today (even if it's already started but not ended)
       
      } else if (eventDate > today && eventDate <= endOfWeek) {
        categorized.thisWeek.push(event);
      } else if (eventDate > endOfWeek) {
        categorized.upcoming.push(event);
      }
    });

    return categorized;
  };

  const categorizedEvents = categorizeEvents(events);

  const handleRsvp = async (event) => {
    const user = auth.currentUser;

    if (!user) {
      setShowSignup(true);  // Show signup form if user is not authenticated
      return;
    }

    try {
      const eventRef = doc(db, 'events', event.id);

      // Update the event document by adding the user's email to the RSVPs array
      await updateDoc(eventRef, {
        rsvps: arrayUnion(user.email)
      });

      // Set RSVP message only for this specific event
      setRsvpMessages(prev => ({
        ...prev,
        [event.id]: `Congratulations! You have RSVP'd to ${event.name}. You have ${event.coins} NeighborhoodCoins pending for participating.`
      }));
    } catch (err) {
      setError('Failed to RSVP: ' + err.message);
    }
  };

  // Check if the current time is between the event's start time and end time
  const isRsvpAllowed = (eventDate, eventEndTime) => {
    const eventEndDateTime = new Date(`${eventDate.toISOString().split('T')[0]}T${eventEndTime}:00`);
    return currentTime <= eventEndDateTime;  // Allow RSVP until the event ends
  };

  const getEventLabel = (ticketPrice) => {
    return ticketPrice && ticketPrice > 0 ? "PAID" : "FREE";
  };

  const renderEvents = (eventsList, categoryTitle) => {
    if (eventsList.length === 0) return null;

    return (
      <>
        <h3 className="text-2xl bg-tealLight mt-4 font-bold py-4 px-4 l text-teal mb-4">{categoryTitle}</h3>

        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 gap-6">
          {eventsList.map(event => (
            <div key={event.id} className="p-4 bg-gray-100 rounded-lg shadow-md">

          <div className="relative">
          <Link to={`/events/${event.id}`}>
            <img src={event.imageUrl} alt={event.name} className="w-full h-60 object-fit rounded-t-lg mb-4"
              style={{
                backgroundImage: `url(${event.imageUrl})`,
                backgroundSize: 'cover',
                backgroundPosition: 'top',  // Adjust this based on what part of the image is important (e.g., 'top', 'bottom', 'left')
                backgroundRepeat: 'no-repeat',
              }}
            />
            </Link>
          </div>

              <Link to={`/events/${event.id}`} className="text-coral font-bold py-4">
                <h4 className="text-2xl font-semibold text mb-2">{event.name}</h4>
              </Link>
              <p className="text-neutral mb-2">
                <span className="font-bold">Date:</span>{' '}
                <span className="font-medium font-bold">
                  {new Date(event.date).toLocaleDateString('en-US', {
                    year: 'numeric',
                    month: 'long',
                    day: '2-digit',
                    timeZone: 'America/New_York'
                  })}
                </span>
              </p>

              <p className="text-neutral mb-2">
                <span className="font-bold">Price:</span> <span className="font-medium">{getEventLabel(event.ticketPrice)}</span>
              </p>
              <p className="text-teal text-small">
                <FontAwesomeIcon icon={faMapMarkerAlt} className="text-primary ml-2 cursor-pointer" onClick={() => handleIconClick(event.id)} />
                <Link to={`/miami/${encodeURIComponent(event.neighborhood)}`}><span className="font-medium ml-2">{event.neighborhood}</span></Link>
              </p>

              <div className="flex justify-between items-center">
                <div>
                  {/* RSVP Button logic */}
                  {isRsvpAllowed(event.date, event.endTime) ? (
                    <button onClick={() => handleRsvp(event)} className="bg-primary block text-white py-2 px-4 m-3 rounded hover:bg-highlight transition-colors duration-300">
                      Join Event
                    </button>
                  ) : (
                    <p className="text-red-500">RSVP has ended.</p>
                  )}
                </div>
                <div>
                  <div className="claim-coins text-white py-1 px-3 rounded-full flex items-center justify-center w-20 h-20" style={{
                    backgroundImage: `url(${CoinImage})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                  }}>
                    <Link to={{ pathname: `/events/${event.id}`, state: { event } }}>
                      <p className="text-center">
                        <small className="block text-sm m-0 text-shadow">Claim</small>
                        <strong className="block text-2xl m-0 text-shadow">+ {event.coins}</strong>
                        <span className="block text                        -sm mt-0 text-shadow">Coins</span>
                      </p>
                    </Link>
                  </div>
                </div>
              </div>

              {/* Show the RSVP message only for this event if it exists */}
              {rsvpMessages[event.id] && <strong><p className="text-primary mt-4">{rsvpMessages[event.id]}</p></strong>}

              {/* Conditionally render the map within the card if this event is selected */}
              {selectedEventId === event.id && (
                <div className="mt-4">
                  <Map event={event} />
                </div>
              )}
            </div>
          ))}
        </div>
      </>
    );
  };

  // Function to sort events by date in ascending order
  const sortEventsByDate = (events) => {
    return events.sort((a, b) => {
      const dateA = new Date(a.date instanceof Object && a.date.toDate ? a.date.toDate() : a.date);
      const dateB = new Date(b.date instanceof Object && b.date.toDate ? b.date.toDate() : b.date);
      return dateA - dateB;
    });
  };

  // Sort categorized events
  const sortedTodayEvents = sortEventsByDate(categorizedEvents.today);
  const sortedThisWeekEvents = sortEventsByDate(categorizedEvents.thisWeek);
  const sortedUpcomingEvents = sortEventsByDate(categorizedEvents.upcoming);

  return (
    <div className="container mx-auto px-4 py-8 bg-white rounded-lg shadow-lg">
      <div
        style={{
          backgroundImage: `url(${Banner})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          height: '200px',
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginBottom: '2px',
        }}
      >
        <h2 className="text-3xl text-gray font-bold mb-6 text-center uppercase">Miami <br /> Community <br />Events</h2>
      </div>
    
      {/* Conditionally render the Signup form if user is not authenticated */}
      {showSignup && (
        <div className="mt-6">
          <h3 className="text-xl font-bold text-primary mb-4">Please Sign Up to RSVP</h3>
          <GoogleSignInButton />
        </div>
      )}

      {/* Render Events by Category */}
      {renderEvents(sortedTodayEvents, 'Today')}
      {renderEvents(sortedThisWeekEvents, 'This Week')}
      {renderEvents(sortedUpcomingEvents, 'Upcoming Events')}
    </div>
  );
};

export default Events;