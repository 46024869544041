import { getAuth, signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { db } from '../firebase';  // Firestore instance
import { doc, getDoc, setDoc } from 'firebase/firestore';  // Firestore methods

const GoogleSignInButton = () => {
  const navigate = useNavigate();

  const handleGoogleSignIn = async () => {
    const provider = new GoogleAuthProvider();
    const auth = getAuth();
    
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;

      // Check if the user already exists in Firestore and has a role
      const userRef = doc(db, 'users', user.uid);
      const userDoc = await getDoc(userRef);
      if(!userDoc.exists()) {
        // If user does not exist, create a new user document in Firestore
        await setDoc(userRef, {
          email: user.email,
          name: user.displayName,
          neighborCoinBalance: 0,
          role: 'attendee', // Default role
          createdAt: new Date()
        });
        // If no role exists, redirect to the role-signup page to choose a role
        navigate('/signups', { state: { email: user.email } });
      } else {

     //Fetch the user's role from Firestore and redirect to appropriate page
      const userRole = userDoc.exists() ? userDoc.data().role : 'attendee';
        // Redirect user based on their role
        switch (userRole) {
          case 'attendee':
            navigate('/dashboard');
            break;
          case 'eventPlanner':
            navigate('/event-admin');
            break;
          case 'businessOwner':
            navigate('/business-dashboard');
            break;
          default:
            navigate('/dashboard');
        }
      }     
      } catch (error) {
      console.error("Google Sign-In Error:", error);
    }
  };

  return (
    <div className="bg-mustard px-4 py-4">
    <h2 className="text-center text-xl  text-neutral">Welcome to NeighborhoodCoins</h2>
    <p className="text-center text-neutral mb-4">Sign in with your Google account.</p>
    <button onClick={handleGoogleSignIn} className="w-full bg-coral text-white py-2 px-4 font-bold rounded-lg hover:bg-burntOrange ">
      Sign in with Google
    </button>
    </div>
  );
};

export default GoogleSignInButton;