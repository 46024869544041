import React, { useEffect, useState } from 'react';
import { functions, auth, storage, db } from '../firebase';  
import {doc, getDoc, addDoc, collection } from 'firebase/firestore'; 
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';  
import { Link } from 'react-router-dom';
import { httpsCallable } from 'firebase/functions';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { Timestamp } from 'firebase/firestore';
import axios from 'axios';
import Profile from  '../pages/Profile';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboardCheck, faStoreAlt, faCheckCircle, faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import CoinImage from '../assets/coin_blank.png';


const EventForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    description: '',
    date: '',
    location: '',//This is the address of the event
    venue: '',
    neighborhood: '',
    coins: 0,
    startTime: '',
    endTime: '',
    doorsOpenTime: '',
    organizer: '',
    reward: '',
    ticketPrice: '', // Allows empty ticket pricing
    ticketQuantity: '', // Allows empty ticket quantity
  });
  const [latLong, setLatLong] = useState(null);
  const [mapLoading, setMapLoading] = useState(false);
  const [imageFile, setImageFile] = useState(null);
  const [errors, setErrors] = useState({});
  const [imageUrl, setImageUrl] = useState('');
  const [message, setMessage] = useState(null);
  const [userData, setUserData] = useState(null);
  const [uploading, setUploading] = useState(false); 
  const [modalOpen, setModalOpen] = useState(false);
  const [isEventsOpen, setIsEventsOpen] = useState(false);
  const [isProfileOpen, setIsProfileOpen] = useState(false);
  const [isRewardsOpen, setIsRewardsOpen] = useState(false);

 
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({ ...prevData, [name]: value }));
  };

  const geocodeAddress = async (address) => {
    try {
      setMapLoading(true);
      const response = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json`, {
        params: {
          address,
          key: process.env.REACT_APP_GOOGLE_GEOCODE_API_KEY, // Your API key
        }
      });
      console.log(response.data);  // Log the response to check
      if (response.data.results.length > 0) {
        const location = response.data.results[0].geometry.location;
        setLatLong({ lat: location.lat, lng: location.lng }); // Save the latitude and longitude
        return { lat: location.lat, lng: location.lng }; // Return latitude and longitude
      } else {
        console.error("No results found for the provided address");
        return null; // Return null if geocoding fails
      }
    } catch (error) {
      console.error("Geocoding API error: ", error);
      return null; // Return null in case of error
    } finally {
      setMapLoading(false);
    }
  };

// Call geocodeAddress when user finishes typing in the location field
const handleAddressBlur = async (e) => {
  const { value } = e.target;
  if (value) {
    const location = await geocodeAddress(value);
    if (location) {
      // Successfully got lat/lng, you can store it in state or pass it to a function
      setLatLong(location);  // This stores lat/lng for later use
      console.log('Lat/Long:', location.lat);  // Check if it logs the correct values
    } else {
      console.error('Failed to fetch lat/long for the provided address');
    }
  }
};

  const handleImageChange = (e) => {
    setImageFile(e.target.files[0]);  // Store the selected image file
  };

  // Validation
  const validateForm = () => {
    const newErrors = {};
    
    if (!formData.description.trim()) {
      newErrors.description = 'Description is required';
    }
    
    if (!formData.date) {
      newErrors.date = 'Date is required';
    }

    if (!formData.location.trim()) {
      newErrors.location = 'Location is required';
    }

    if (formData.coins < 1 || formData.coins > 5) {
      newErrors.coins = 'Please enter a valid number of NeighborhoodCoins (1-5)';
    }

    // Ticket validation
    if (formData.ticketPrice && formData.ticketPrice < 0) {
      newErrors.ticketPrice = 'Ticket price cannot be negative';
    }
    if (formData.ticketQuantity && formData.ticketQuantity < 1) {
      newErrors.ticketQuantity = 'You must have at least 1 ticket available';
    }

    // Validate time (start time must be after door open time)
    if (formData.doorsOpenTime && formData.startTime && formData.startTime < formData.doorsOpenTime) {
      newErrors.doorsOpenTime = 'Doors Open time must be before the start time';
    }

    return newErrors;
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Validate form
    const newErrors = validateForm();
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }
  
    //Prevent multiple form submissions
    setUploading(true);

    // Fetch lat/long based on the address
    const location = await geocodeAddress(formData.location);
    if (!location) {
      setMessage("Invalid address.");
      return;
    }
  
    // Use the user's local date without forcing UTC
    const userDate = new Date(formData.date + "T00:00:00"); // Date without any timezone conversion
    const eventDate = Timestamp.fromDate(userDate); // Convert to Firestore Timestamp
  

    try {
      const auth = getAuth();
      const user = auth.currentUser;
      if (!user) {
        setMessage('You need to be logged in to create an event.');
        return;
      }
  
      // Upload image to Firebase Storage
      let imageUrl = '';
      if (imageFile) {
        const imageRef = ref(storage, `events/${imageFile.name}`);
        const uploadTask = uploadBytesResumable(imageRef, imageFile);
  
        // Wait for the upload to complete and get the download URL
        await new Promise((resolve, reject) => {
          uploadTask.on('state_changed', 
            null, 
            (error) => reject(error), 
            async () => {
              imageUrl = await getDownloadURL(uploadTask.snapshot.ref);
              resolve();
            });
        });
      }
  
      // Prepare form data with normalized date and organizerId(uid)
      const eventData = {
        ...formData,
        date: eventDate, // Save the Firestore Timestamp
        imageUrl: imageUrl,  // Save the image URL
        latitude: location.lat,  // Store latitude
        longitude: location.lng,  // Store longitude
        organizerId: user.uid, // Save the organizerId as user's ID
      };
  
      const eventsRef = collection(db, 'events');
      await addDoc(eventsRef, eventData);  // Save to Firestore
      setMessage('Awesome! Your Event successfully added to NeighborhoodCoins Events! See the Events page. EDIT it on the Events Details page.');
      setModalOpen(true);
    } catch (error) {
      console.error('Error adding event: ', error);
      setMessage('Error adding event. Please try again.');
    } finally {
      setUploading(false);
    }
  };

   const closeModal = () => {
    setModalOpen(false);
  };
  // Fetch user data when component mounts
  useEffect(() => {
    const auth = getAuth();
    
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        // Fetch user profile from Firestore
        const userRef = doc(db, 'users', user.uid);
        const userDoc = await getDoc(userRef);
        
        if (userDoc.exists()) {
          setUserData(userDoc.data());  // Set the user data
        } else {
          setMessage('User data not found');
        }
      }
    });
  }, []);


  return (
    <div className="flex flex-col items-center justify-center min-h-screen p-6 bg-white rounded-lg shadow-lg max-w-4xl">
    <h1 className="text-3xl font-bold text-primary mb-4">
    Welcome, {userData ? userData.displayName : 'User'}
      </h1>
    <h5 className="text-1xl font-bold  mb-4"> 
      Your NeighborhoodCoins Balance: 
      </h5>
      <div className="text-white py-1 px-3 mb-3 rounded-full flex items-center justify-center w-20 h-20" style={{
              backgroundImage: `url(${CoinImage})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
            }}>
              <p className="text-center">
               {userData ? userData.neighborCoinBalance : 'Loading...'}
                <span className="block text-sm text-shadow">Coins</span>
              </p>
            </div>
 
    <div className="flex justify-center">
        <Link to="/rewards"> <button className="border-3 border-solid border-primary text-primary py-2 px-4 mb-3 rounded-lg hover:bg-highlight">
          Redeem Coins
        </button>
        </Link>
      </div>
    
    {/* Events Section */}
    <div className="mb-8 bg-orange p-4 rounded-lg">
        <h2 className="text-2xl font-semibold text-accent mb-4 flex justify-between items-center cursor-pointer" onClick={() => setIsEventsOpen(!isEventsOpen)}>
          Create a New Event
          <FontAwesomeIcon icon={isEventsOpen ? faChevronUp : faChevronDown} />
        </h2>
        {isEventsOpen && (
          <>
            <form onSubmit={handleSubmit}>
              {/* Event Name */}
              <div className="mb-4">
                <label className="block text-neutral mb-2">Event name (Max 50 characters)</label>
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  maxLength="50"
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg"
                  required
                />
              </div>
              {/* Event Description */}
              <div className="mb-4">
                <label className="block text-neutral mb-2">Event Description (Max 500 characters)</label>
                <textarea
                  name="description"
                  value={formData.description}
                  onChange={handleChange}
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg"
                  rows="4"
                  placeholder="Enter event description"
                  maxLength="500"
                  required
                ></textarea>
                {errors.description && <p className="text-red-500 text-xs mt-1">{errors.description}</p>}
              </div>
              {/* Date */}
              <div className="mb-4">
                <label className="block text-neutral mb-2">Event Date</label>
                <input
                  type="date"
                  name="date"
                  value={formData.date}
                  onChange={handleChange}
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg"
                  required
                />
              </div>
              {/* Event Start Time */}
              <div className="mb-4">
                <label className="block text-neutral mb-2">Event Start Time</label>
                <input
                  type="time"
                  name="startTime"
                  value={formData.startTime}
                  onChange={handleChange}
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg"
                  required
                />
              </div>
              {/* Event End Time */}
              <div className="mb-4">
                <label className="block text-neutral mb-2">Event End Time</label>
                <input
                  type="time"
                  name="endTime"
                  value={formData.endTime}
                  onChange={handleChange}
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg"
                  required
                />
              </div>
              {/* Doors Open Time */}
              <div className="mb-4">
                <label className="block text-neutral mb-2">Doors Open Time</label>
                <input
                  type="time"
                  name="doorsOpenTime"
                  value={formData.doorsOpenTime}
                  onChange={handleChange}
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg"
                  required
                />
              </div>
              {/* Venue Name */}
              <div className="mb-4">
                <label className="block text-neutral mb-2">Event Venue Name</label>
                <input
                  type="text"
                  name="venue"
                  value={formData.venue}
                  onChange={handleChange}
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg"
                  required
                />
                {errors.date && <p className="text-red-500 text-xs mt-1">{errors.date}</p>}
              </div>
              {/* Location */}
              <div className="mb-4">
                <label className="block text-neutral mb-2">Event address</label>
                <input
                  type="text"
                  name="location"
                  value={formData.location}
                  onChange={handleChange}
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg"
                  onBlur={handleAddressBlur}
                  required
                />
              </div>
              {/* Number of Neighborhood Coins (limit to 5) */}
              <div className="mb-4">
                <label className="block text-neutral mb-2">NeighborhoodCoins (Max 5)</label>
                <input
                  type="number"
                  name="coins"
                  value={formData.coins}
                  onChange={handleChange}
                  max="5"
                  min="0"
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg"
                  required
                />
              </div>
              {/* Reward Offering */}
              <div className="mb-4">
                <label className="block text-neutral mb-2">Reward Offering</label>
                <input
                  type="string"
                  name="reward"
                  placeholder="Enter perk that attendees can earn more coins"
                  value={formData.reward}
                  onChange={handleChange}
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg"
                />
              </div>
              {/* Event Image */}
              <div className="mb-4">
                <label className="block text-neutral mb-2">Event Image</label>
                <input
                  type="file"
                  onChange={handleImageChange}
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg"
                  required
                />
              </div>
              <div className="mb-4">
                <label className="block text-neutral mb-2">Neighborhood (Optional)</label>
                <input
                  type="text"
                  name="neighborhood"
                  value={formData.neighborhood}
                  onChange={handleChange}
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg"
                  placeholder="Optional"
                />
              </div>
              <div className="mb-4">
                <label className="block text-neutral mb-2">Ticket Price ($) (Optional)</label>
                <input
                  type="number"
                  name="ticketPrice"
                  value={formData.ticketPrice}
                  onChange={handleChange}
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg"
                  placeholder="Optional"
                />
              </div>
              <div className="mb-4">
                <label className="block text-neutral mb-2">Ticket Quantity (Optional)</label>
                <input
                  type="number"
                  name="ticketQuantity"
                  value={formData.ticketQuantity}
                  onChange={handleChange}
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg"
                  placeholder="Optional"
                />
              </div>
              <div className="mb-4">
                <label className="block text-neutral mb-2">Organizer Company Name</label>
                <input
                  type="text"
                  name="organizer"
                  value={formData.organizer}
                  onChange={handleChange}
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg"
                  required
                />
              </div>
              {uploading ? (
                <p>Uploading image...</p>
              ) : (
                <button type="submit" className="bg-primary text-white py-2 px-4 rounded-lg hover:bg-highlight" disabled={uploading}>
                  {uploading ? 'Adding Event...' : 'Add Event'}
                </button>
              )}
            </form>
          </>
        )}
      </div>
     {/* Modal for success message */}
     {modalOpen && (
        <div className="modal fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <h3 className="text-xl font-semibold text-center">Success!</h3>
            <p className="text-center mt-4">{message}</p>
            <button
              className="mt-4 bg-primary text-white py-2 px-4 rounded-lg hover:bg-highlight"
              onClick={closeModal}
            >
              Close
            </button>
          </div>
        </div>
      )}
 
    
      {/* Profiles Section */}
      <div className="mb-8 bg-lightPurple p-4 rounded-lg">
        <h3 className="text-2xl font-semibold text-accent mb-4 flex justify-between items-center cursor-pointer" onClick={() => setIsProfileOpen(!isProfileOpen)}>
          Update Your Profile
          <FontAwesomeIcon icon={isProfileOpen ? faChevronUp : faChevronDown} />
        </h3>
        {isProfileOpen && (
          <>
            <Profile />
          </>
        )}
      </div>
    
      {/* Rewards Section */}
      <div className="mb-8 bg-green p-4 rounded-lg">
        <h3 className="text-2xl font-semibold text-accent mb-4 flex justify-between items-center cursor-pointer" onClick={() => setIsRewardsOpen(!isRewardsOpen)}>
          Available Rewards
          <FontAwesomeIcon icon={isRewardsOpen ? faChevronUp : faChevronDown} />
        </h3>
        {isRewardsOpen && (
          <>
            <p className="text-neutral mb-4">
              Redeem your NeighborhoodCoins for discounts and rewards at local businesses. Check out the rewards page to see what's available.
            </p>
            <Link to="/rewards" className="bg-primary text-white py-2 px-4 rounded-lg hover:bg-highlight flex items-center justify-center">
              <FontAwesomeIcon icon={faStoreAlt} className="mr-2" />
              View Rewards
            </Link>
          </>
        )}
      </div>
      
  
    {message && <p className="font-bold text-greenDark">{message}</p>}
    
  </div>
  
  );
};

export default EventForm;